import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ThemeReducer from './ThemeReducer';
import WebsocketReducer from './WebsocketReducer';
import NotifierReducer from './NotifierReducer';
import UiReducer from './UiReducer';
import UserReducer from './UserReducer';
import VfcReducer from './VfcReducer';
import AppInfoReducer from './AppInfoReducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    theme: ThemeReducer,
    websocket: WebsocketReducer,
    notifier: NotifierReducer,
    ui: UiReducer,
    user: UserReducer,
    vfc: VfcReducer,
    appInfo: AppInfoReducer,
  });

export default createRootReducer;
