/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ColumnResizer from 'react-column-resizer';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const borderColor = '#ccc';

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflow: 'auto',
    margin: '0 auto',
    position: 'relative'
  },
  table: {
    width: '100%',
    marginBottom: 10,
    boxSizing: 'border-box',
    border:  `1px solid ${borderColor}`,
    '& tr': {
      borderBottom: `1px solid ${borderColor}`,
      '&:first-child': {
        borderTop: `1px solid ${borderColor}`
      },
      '&:hover': {
        background: 'rgba(127, 127, 127, 0.1)',
        '&:first-child': {
          background: 'inherit'
        }
      }
    },
    '& th': {
      textAlign: 'center',
      whiteSpace: 'nowrap',
      height: 25,
      borderRight: `1px solid ${borderColor}`,
      position: 'relative',
      '&:last-child': {
        borderRight: 'none'
      },
      '& span': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingTop: 2,
      },
      '& svg': {
        fontSize: 16,
        color: '#888'
      }
    },
    '& td': {
      borderRight: `1px solid ${borderColor}`,
      whiteSpace: 'nowrap',
      position: 'relative',
      height: '1.5em',
      fontSize: 13,
      '& > span': {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        boxSizing: 'border-box',
        padding: '2px 4px'
      },
      '&:first-child > span': {
        paddingLeft: 10,
        background: 'left'
      },
      '&:last-child': {
        borderRight: 'none !important',
        '& > span': {
          paddingRight: 10
        }
      }
    }
  },
  header: {
    cursor: 'pointer'
  },
  divider: {
    width: 1,
    position: 'relative',
    borderRight: 'none !important',
    borderLeft: 'none !important',
    '&:after': {
      position: 'absolute',
      content: '""',
      top: 1,
      bottom: 1,
      left: -2,
      width: 5,
      opacity: 0.01
    }
  },
  dividerTd: {
    width: 0,
    borderRight: 'none !important'
  },
  spinner: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#999'
    }
  }
}));

const stylesDay = {
  selected: {
    background: '#cde2f6'
  },
  spinner: {
    background: 'rgba(255, 255, 255, 0.5)'
  }
};

const stylesNight = {
  selected: {
    background: '#333'
  },
  spinner: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

const styles = themeMode => {
  return themeMode === 'day' ? stylesDay : stylesNight;
};

let lastClick = { idx: -1, time: 0};

function ReportTable(props) {
  const classes = useStyles();
  const { data, config, sort, themeMode } = props;
  const { orderBy, rowsPerPage, orderDirection, filters, loading, count } = data;
  const [headCells, setHeadCells] = useState(getHeadCells(data.rows, config));
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setHeadCells(getHeadCells(data.rows, config));
    setSelected(null);
  }, [data]);

  useEffect(() => {
    props.selectRow(selected !== null ? data.rows[selected] : null);
  }, [selected]);

  const handlePageChange = (ev, pageNo) => {
    const options = {
      pageNo,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection,
      count,
    };
    props.getData(options);
  };

  const handleChangeRowsPerPage = (ev, info) => {
    const rowsPerPage = parseInt(info.key);
    const options = {
      pageNo: 0,
      filters,
      orderBy,
      rowsPerPage,
      orderDirection,
      count,
    };
    props.getData(options);
  };

  const Spinner = () => {
    return (
      <div className={classes.spinner} style={styles(themeMode).spinner}>
        <CircularProgress variant="indeterminate" disableShrink />
      </div>
    );
  };

  const handleRowClick = (idx) => {
    if (checkDblClick(idx)) {
      if (props.dblClick) props.dblClick();
    } else if (selected !== idx){
      setSelected(idx);
    }
  };

  const checkDblClick = idx => {
    const time = getTimestamp();
    const dblClick = lastClick.idx === idx && (time - lastClick.time) < 450;
    lastClick = { idx, time };
    return dblClick;
  }

  return (
    <Fragment>
      <div className={classes.tableWrap}>
        <table className={classes.table}>
          <thead>
            <tr>
              {headCells.map((row, idx) => {
                return (
                  <Fragment key={row.label}>
                    {idx > 1 && (
                      <ColumnResizer
                        className="columnResizer"
                        className={classes.divider}
                        minWidth={20}
                      />
                    )}
                    { idx>0 && (
                    <th
                      className={classes.header}
                      style={{ width: row.width }}
                      onClick={() => sort(row.dbName)}
                      title={row.title}>
                      <span>
                        {row.label}
                        {row.dbName === orderBy && orderDirection === 'ASC' && (
                          <ArrowDownwardIcon />
                        )}
                        {row.dbName === orderBy && orderDirection === 'DESC' && (
                          <ArrowUpwardIcon />
                        )}
                      </span>
                    </th>
                    )}
                  </Fragment>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.rows.map((row, idx) => {
              let cols = [];
              Object.entries(row).forEach(([key, val]) => {
                if (key !== 'key') {
                  const dataType = config.display[key].type;
                  if (val && dataType === 'date' && moment(val).isValid()) {
                    cols.push({text: moment(val).format('MM/DD/YYYY'), seq: config.display[key].seq });
                  } else {
                    cols.push({ text: val, seq: config.display[key].seq });
                  }
                }
              });
              if (cols.length > 0 && cols[0].seq !== undefined) {
                cols = cols.sort((a, b) => a.seq - b.seq);
              }
              return (
                <tr
                  key={idx}
                  // dataset = {cols[0].text}
                  onClick={() => handleRowClick(idx)}
                  style={selected === idx ? styles(themeMode).selected : {}}>
                  {cols.map((obj, idx) => (
                    <Fragment key={idx}>
                      {idx > 1 && <td className={classes.dividerTd} />}
                      {idx > 0 && (
                      <td title={obj.text} align="center">
                        <span>{ obj.text }</span>
                      </td>
                      )}
                    </Fragment>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        rowsPerPageOptions={config.pageSizeOptions}
        component="div"
        count={count}
        rowsPerPage={data.rowsPerPage}
        page={data.pageNo}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {loading && <Spinner />}
    </Fragment>
  );
}

const mapStatetoProps = state => {
  return {
    themeMode: state.theme.mode
  };
};

export default connect(mapStatetoProps)(ReportTable);

// ======================= HELPER FUNCTIONS ====================

function getHeadCells(dataObj, config) {
  const result = [];
  if (dataObj.length === 0) return [];
  const row = dataObj[0];
  Object.keys(row).forEach(key => {
    const option = config.display[key];
    if (key !== 'key') {
      result.push({
        name: key,
        dbName: option.dbName,
        label: option ? option.label : key,
        width: getColWidth(key, config),
        title: option.title ? option.title : option.label,
        seq: option.seq,
      });
    }
  });
  
  if (result.length > 0 && result[0].seq !== undefined) {
    return result.sort((a, b) => a.seq - b.seq);
  }
  return result;
}

function getTableWidth(config) {
  return Object.values(config.display).reduce((sum, val) => val.width + sum, 0);
}

function getColWidth(key, config) {
  return `${Math.round(
    (config.display[key].width / getTableWidth(config)) * 10000
  ) / 100}%`;
}

function getTimestamp() {
  return new Date().getTime();
}
