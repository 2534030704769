/**
 * Getting information about app version and available updates waiting to install
 */
import buildInfo from '../config/buildInfo.json';
import store from '../config/configureStore';
import moment from 'moment';

const GET_API_VERSION = 'APPINFO/GET_API_VERSION';
const CHECK_FOR_UPDATES = 'APPINFO/CHECK_FOR_UPDATES';

const abbBuildTime = moment(buildInfo.time).isValid() ? timeStrFromTimestamp(buildInfo.time) : '?';

const defaultState = {
  apiVersion: '?',
  buildNo: buildInfo.build,
  buildTime: abbBuildTime,
  availBuildNo: '?',
  availBuildTime: '?',
};

// ======================= ACTIONS =======================

/** Get Api Version - must be implemented on api side */
export const getApiVer = () => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async dispatch => {
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      const data = await service.get({ type: 'version-info' });
      dispatch({ type: GET_API_VERSION, payload: data.ver });
    } catch (error) {
      console.log('Cannot retrieve api version');
    }
  };
};

export const checkForUpdates = () => {
  return async dispatch => {
    try {
      const result = await checkForNewVersion();
      dispatch({ type: CHECK_FOR_UPDATES, payload: result });
    } catch (error) {
      console.log('Cannot get updates information #2');
    }
  };
};

export const updateIfNewVersionAvail = () => {
  const appInfo = store.store.getState().appInfo;
  return async dispatch => {
    try {
      const result = await checkForNewVersion();
      const buildTime = timeStrFromTimestamp(result.time);
      if (appInfo.buildNo !== result.build || appInfo.buildTime !== buildTime) {
        window.location.reload(true);
      }
    } catch (error) {
      console.log('Cannot get updates information #3');
    }
  };
};

// ======================= REDUCER =======================
export default function reducer(state = defaultState, action) {
  let time, build, buildTime;
  switch (action.type) {
    case GET_API_VERSION:
      return { ...state, apiVersion: action.payload };
    case CHECK_FOR_UPDATES:
      time = action.payload.time;
      build = action.payload.build;
      if (moment(time).isValid()) {
        buildTime = timeStrFromTimestamp(time);
      } else {
        buildTime = state.availBuildTime;
      }
      return { ...state, availBuildNo: build, availBuildTime: buildTime };
    default:
      return state;
  }
}

// ======================= HELPER FUNCTIONS =======================

function checkForNewVersion() {
  return new Promise((resolve, reject) => {
    fetch(`/buildInfo.json?ver=${new Date().getTime()}`)
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject('Cannot get updates information #1');
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

function timeStrFromTimestamp(timestamp) {
  return moment(timestamp).format('MM/DD/YYYY HH:mm:ss');
}
