import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Dialog, DialogActions, DialogContent, DialogTitle, Button
} from '@material-ui/core';
import DisplayData, { Wrapper } from '../../components/DisplayData';
import htmlForPrinting from '../../components/DisplayData/htmlForPrinting';

const useStyles = makeStyles(theme => ({
  body: {
    background: '#eee',
  },
  printIframe: {
    height: 0,
    width: 0,
    position: 'absolute'
  },
}));

function CaseDetails (props) {
  const { data } = props;
  if (!data) return '';

  return(
    <Wrapper caseNo={props.caseNo}>
      <DisplayData
        data={data}
        variant="V"
        filters={[ 
          'MainInfo', 'AddtlInfo',  'Notes'
        ]}
        // 'Number', 'Type', 'Officer',
        title={'Event Details'}
        caseNo={props.caseNo}
      />
      <DisplayData 
        variant="H"
        subtitle="Additional Info"
        caseNo={props.vfcMainId} //caseNo
        data={data.AddtlInfo}
      />
    </Wrapper>
  );
}

function MainData (props) {
  const { data } = props;
  if (!data) return '';

  return(
    <Wrapper caseNo={props.caseNo}>
      <h4>Main Info</h4>
      {data.map((d, idx) => <DisplayData key={idx} variant="V" data={d}/>)}
    </Wrapper>
  );
}

function DispatchCalls(props) {
  const { data } = props;
  if (!data) return '';

  return (
    <Wrapper caseNo={props.caseNo}>
      {data.map((d, idx) => (
        <Fragment key={idx}>
          <DisplayData variant="V" data={d} title="Dispatch Call" filters={['AddtlInfo', 'MainInfo']}/> 
          <DisplayData variant="V" data={d.MainInfo} subtitle="Main Info" />
          {/* <DisplayData variant="V" data={d.Info} subtitle="Info" /> */}
          <DisplayData variant="H" data={d.AddtlInfo} subtitle="Additional Info" />
        </Fragment>
      ))}
    </Wrapper>
  );
}

function DispatchOfficers(props) {
  const { data } = props;
  if (!data) return '';

  return (
    <Wrapper caseNo={props.caseNo}>
      <h4>Dispatch Officers</h4>
      {data.map((d, idx) => <DisplayData key={idx} variant="V" data={d} filters={['vfcDispatchOfficerId', 'vfcDispatchCallId']}/>)}
    </Wrapper>
  );
}

function DispatchNotes(props) {
  const { data } = props;
  if (!data) return '';

  return (
    <DisplayData 
      variant="H"
      data={data}
      filters={['PSRNumber', 'Notes']}
      title="Dispatch Notes"
      paper/>
  );
}

function Report(props) {
  const classes = useStyles();
  const { data } = props;
  const caseNo = data.vfcMainId;//PSRNumber;

  useEffect(() => {
    console.log("Report", data);    
  }, [data]);

  const handleClose = () => {
    props.close();
  };

  const printAll = () => {
    const content = document.getElementById("report-dialog");
    let html = htmlForPrinting(content.innerHTML, `PTS Archive Browser CAD Events | PSRNo: ${caseNo}`);
    const pri = document.getElementById("print-iframe").contentWindow;
    pri.document.open();
    pri.document.write(html);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  if (!data) return 'No data';

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      maxWidth={'md'}
    >
      <iframe id="print-iframe" className={classes.printIframe} title="PTS Archive Browser "></iframe>
      <DialogTitle id="scroll-dialog-title">Event Details</DialogTitle>
      <DialogContent dividers={true} className={classes.body} id="report-dialog">     
        <CaseDetails data={data} caseNo={caseNo}/>
        <MainData data={data.MainInfo} caseNo={caseNo} />
        {/* <DispatchCalls data={data.DispatchCalls} caseNo={caseNo} />
        <DispatchOfficers data={data.DispatchOfficers} caseNo={caseNo} /> */}
        <DispatchNotes data={data.DispatchNotes} caseNo={caseNo} />
      </DialogContent>
      <DialogActions>
      <Button onClick={printAll} color="primary">
          Print
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Report;
