// Layout Blueprints
import {
  MainLayout,
  // PagesLayout,
  // CollapsedSidebar,
  // LeftSidebar,
  // PresentationLayout
} from './layout-blueprints';

// Pages
import Cases from './pages/Cases';
import Events from './pages/Events';
import Booking from './pages/Booking';
// import PageDashboard from './pages/PageDashboard';
// import PageDeveloper from './pages/PageDeveloper';
// import ExamplePage from './example-pages/RegularTables1';

export default [
  { url: '/Cases',   component: Cases,   layout: MainLayout },
  { url: '/Events',  component: Events,  layout: MainLayout },
  { url: '/Booking', component: Booking, layout: MainLayout },
];
