import { notify } from './NotifierReducer';
import store from '../config/configureStore';
import buildInfo from '../config/buildInfo.json';
import casesTableConfig from '../config/cases-table.json';
import eventsConfig from '../config/cad-events-table.json';
import bookingsConfig from '../config/booking-data-table.json'
import { showSpinner, hideSpinner } from './UiReducer';
import appConfig from '../config/appConfig.json';

const FIND_CASES = 'VFC/FIND_CASES';
const GET_CASE = 'VFC/GET_CASE';
const LOADING_CASES = 'VFC/LOADING_CASES';
const LOADING_CASES_ERROR = 'VFC/LOADING_CASES_ERROR';
const GET_CASE_SELECT_ITEMS = 'VFC/GET_CASE_SELECT_ITEMS';

const FIND_EVENTS = 'VFC/FIND_EVENTS';
const GET_EVENT = 'VFC/GET_EVENT';
const LOADING_EVENTS = 'VFC/LOADING_EVENTS';
const LOADING_EVENTS_ERROR = 'VFC/LOADING_EVENTS_ERROR';
const GET_EVENT_SELECT_ITEMS = 'VFC/GET_EVENT_SELECT_ITEMS';

const FIND_BOOKINGS = 'VFC/FIND_BOOKING';
const GET_BOOKING = 'VFC/GET_BOOKING';
const LOADING_BOOKINGS = 'VFC/LOADING_BOOKING';
const LOADING_BOOKINGS_ERROR = 'VFC/LOADING_BOOKING_ERROR';
const GET_BOOKING_SELECT_ITEMS = 'VFC/GET_BOOKING_SELECT_ITEMS';

const GET_API_VER = 'VFC/GET_API_VER';
const PRINT_DATA = 'VFC/PRINT_DATA';

// ======================= ACTIONS =======================

export const findCases = params => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      dispatch({ type: LOADING_CASES, payload: true });
      const cases = await service.find({ query: { ...params, type: 'cases' } });
      dispatch({ type: FIND_CASES, payload: { params, cases } });
      dispatch({ type: LOADING_CASES, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_CASES_ERROR });
      dispatch(notify('Error, data not received #CA1', 'error'));
    }
  };
};

export const sortCases = orderBy => {
  return async dispatch => {
    const changeDirection = direction => (direction === 'ASC' ? 'DESC' : 'ASC');
    const cases = store.store.getState().vfc.cases;
    const { filters, pageNo, rowsPerPage, count } = cases;
    const orderDirection =
      cases.orderBy === orderBy ? changeDirection(cases.orderDirection) : 'ASC';
    const params = { filters, pageNo, orderBy, rowsPerPage, orderDirection, count };
    dispatch(findCases(params));
  };
};

export const getCase = vfcMainId => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async dispatch => {
    dispatch(showSpinner());
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'cases', data: vfcMainId });
      dispatch({ type: GET_CASE, payload: row });
    } catch (error) {
      dispatch(notify('Error, data not received #CA2', 'error'));
    }
    dispatch(hideSpinner());
  };
};

export const getCasesSelectItems = () => {
  const state = store.store.getState();
  // console.log("getCaseSelectItems");
  // console.log(state);
  const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'cases-select-items', data: '' });
      //console.log(row);
      dispatch({ type: GET_CASE_SELECT_ITEMS, payload: row });
    } catch (error) {
      dispatch(notify('Error, data not received # - cases-select-items', 'error'));
    }
    //dispatch(hideSpinner());
  };
};

export const printCasesData = () => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    const cases = store.store.getState().vfc.cases;
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      dispatch({ type: LOADING_CASES, payload: true });
      const query = {
        query: {
          filters: cases.filters,
          orderBy: cases.orderBy,
          orderDirection: cases.orderDirection,
          type: 'cases',
          pageNo: 0,
          rowsPerPage: appConfig.maxPrintResults
        }
      };
      const printData = await service.find(query);
      dispatch({ type: PRINT_DATA, payload: printData.result });
      dispatch({ type: LOADING_CASES, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_CASES_ERROR });
      dispatch(notify('Error, data not received #CA3', 'error'));
    }
  };
};

export const clearPrintData = () => {
  return async dispatch => {
    dispatch({ type: PRINT_DATA, payload: null });
  };
}

// ================= EVENTS ===================

export const findEvents = params => {
  return async dispatch => {
    const state = store.store.getState();
    // console.log("findEvents");
    // console.log(state);
    const client = state.websocket.websocket;
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      dispatch({ type: LOADING_EVENTS, payload: true });
      const events = await service.find({ query: { ...params, type: 'events' } });
      dispatch({ type: FIND_EVENTS, payload: { params, events } });
      dispatch({ type: LOADING_EVENTS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_EVENTS_ERROR });
      dispatch(notify('Error, data not received #EV1', 'error'));
    }
  };
};

export const sortEvents = orderBy => {
  return async dispatch => {
    const changeDirection = direction => (direction === 'ASC' ? 'DESC' : 'ASC');
    const events = store.store.getState().vfc.events;
    const { filters, pageNo, rowsPerPage } = events;
    const orderDirection =
      events.orderBy === orderBy ? changeDirection(events.orderDirection) : 'ASC';
    const params = { filters, pageNo, orderBy, rowsPerPage, orderDirection };
    dispatch(findEvents(params));
  };
};

export const getEvent = vfcMainId => {
  const state = store.store.getState();
  console.log("getEvent", vfcMainId);
  const client = state.websocket.websocket;
  return async dispatch => {
    dispatch(showSpinner());
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'events', data: vfcMainId });
      dispatch({ type: GET_EVENT, payload: row });
    } catch (error) {
      dispatch(notify('Error, data not received #EV2', 'error'));
    }
    dispatch(hideSpinner());
  };
};

export const getEventsSelectItems = () => {
  const state = store.store.getState();
  //console.log("getEventsSelectItems");
  //console.log(state);
  const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'events-select-items', data: '' });
      //console.log(row);
      dispatch({ type: GET_EVENT_SELECT_ITEMS, payload: row });
    } catch (error) {
      dispatch(notify('Error, data not received #EV2 - events-select-items', 'error'));
    }
    //dispatch(hideSpinner());
  };
};

export const printEventsData = () => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    const events = store.store.getState().vfc.events;
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      dispatch({ type: LOADING_EVENTS, payload: true });
      const query = {
        query: {
          filters: events.filters,
          orderBy: events.orderBy,
          orderDirection: events.orderDirection,
          type: 'events',
          pageNo: 0,
          rowsPerPage: appConfig.maxPrintResults
        }
      };
      const printData = await service.find(query);
      dispatch({ type: PRINT_DATA, payload: printData.result });
      dispatch({ type: LOADING_EVENTS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_EVENTS_ERROR });
      dispatch(notify('Error, data not received #CA3', 'error'));
    }
  };
};

// ================= BOOKING ===================

export const findBookings = params => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      dispatch({ type: LOADING_BOOKINGS, payload: true });
      const bookings = await service.find({ query: { ...params, type: 'bookings' } });
      dispatch({ type: FIND_BOOKINGS, payload: { params, bookings } });
      dispatch({ type: LOADING_BOOKINGS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_BOOKINGS_ERROR });
      dispatch(notify('Error, data not received #EV1', 'error'));
    }
  };
};

export const sortBookings = orderBy => {
  return async dispatch => {
    const changeDirection = direction => (direction === 'ASC' ? 'DESC' : 'ASC');
    const bookings = store.store.getState().vfc.bookings;
    const { filters, pageNo, rowsPerPage } = bookings;
    const orderDirection =
      bookings.orderBy === orderBy ? changeDirection(bookings.orderDirection) : 'ASC';
    const params = { filters, pageNo, orderBy, rowsPerPage, orderDirection };
    dispatch(findBookings(params));
  };
};

export const getBooking = dispatchId => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async dispatch => {
    dispatch(showSpinner());
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'bookings', data: dispatchId });
      dispatch({ type: GET_BOOKING, payload: row });
    } catch (error) {
      dispatch(notify('Error, data not received #EV2', 'error'));
    }
    dispatch(hideSpinner());
  };
};

export const getBookingSelectItems = () => {
  const state = store.store.getState();
   //console.log("getBookingSelectItems");
   //console.log(state);
  const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      const row = await service.get({ type: 'booking-select-items', data: '' });
      //console.log(row);
      dispatch({ type: GET_BOOKING_SELECT_ITEMS, payload: row });
    } catch (error) {
      dispatch(notify('Error, data not received # - booking-select-items', 'error'));
    }
    //dispatch(hideSpinner());
  };
};

export const printBookingsData = () => {
  return async dispatch => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    const bookings = store.store.getState().vfc.bookings;
    try {
      const service = client.service('archive-browser');
      service.timeout = 20000;
      dispatch({ type: LOADING_BOOKINGS, payload: true });
      const query = {
        query: {
          filters: bookings.filters,
          orderBy: bookings.orderBy,
          orderDirection: bookings.orderDirection,
          type: 'bookings',
          pageNo: 0,
          rowsPerPage: appConfig.maxPrintResults
        }
      };
      const printData = await service.find(query);
      dispatch({ type: PRINT_DATA, payload: printData.result });
      dispatch({ type: LOADING_BOOKINGS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_BOOKINGS_ERROR });
      dispatch(notify('Error, data not received #CA3', 'error'));
    }
  };
};

// ======================= REDUCER =======================
const defaultState = {
  cases: {
    rows: [],
    count: 0,
    pageNo: 0,
    search: '',
    rowsPerPage: casesTableConfig.rowsPerPage,
    orderBy: casesTableConfig.orderBy,
    orderDirection: casesTableConfig.orderDirection,
    loading: false,
    loaded: false,
    error: false
  },
  caseDetails: {
    data: null,
    vfcMainId: null
  },
  events: {
    rows: [],
    count: 0,
    pageNo: 0,
    search: '',
    rowsPerPage: eventsConfig.rowsPerPage,
    orderBy: eventsConfig.orderBy,
    orderDirection: eventsConfig.orderDirection,
    loading: false,
    loaded: false,
    error: false
  },
  eventDetails: {
    data: null,
    vfcMainId: null
  },
  bookings: {
    rows: [],
    count: 0,
    pageNo: 0,
    search: '',
    rowsPerPage: bookingsConfig.rowsPerPage,
    orderBy: bookingsConfig.orderBy,
    orderDirection: bookingsConfig.orderDirection,
    loading: false,
    loaded: false,
    error: false
  },
  bookingDetails: {
    data: null,
    vfcMainId: null
  },
  printData: null,
  apiVersion: null,
  eventsSelectItems: {
    EventTypes: [],
    Zones: [],
    Officers: [],
    UnitVehicles: [],
    CallerCity: [],
    CallerZip: [],
    SceneCity: [],
    SceneZip: []
  },
  casesSelectItems: {
    ReportingOfficer: [],
    PartyType: [],
    Race: [],
    Sex: [],
    DLStates: [],
    VehicleMake: [],
    VehicleModel: [],
    LicenseState: [],
    PropertyCode: []
  },
  bookingSelectItems: {
    Race: [],
    Sex: [],
    IDState: [],
    ArrestCity: [],
    ArrestingOfficer: [],
    ArrestingAgency: [],
    BookingOfficer: [],
    ReleasingOfficer: []
  },
  buildInfo
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    // vfc
    case LOADING_CASES:
      return {
        ...state,
        cases: {
          ...state.cases,
          loading: action.payload,
          loaded: action.payload ? false : state.cases.loaded,
          error: false
        }
      };
    case LOADING_CASES_ERROR:
      return {
        ...state,
        cases: {
          ...state.cases,
          loading: false,
          loaded: true,
          error: true
        }
      };
    case FIND_CASES:
      return {
        ...state,
        cases: {
          rows: formatResultsData(action.payload.cases.result, casesTableConfig),
          ...action.payload.params,
          count: action.payload.cases.count,
          loaded: true,
          error: false
        }
      };
    case GET_CASE:
      return {
        ...state,
        caseDetails: { ...state.caseDetails, data: formatCaseData(action.payload) }
      };    
    case LOADING_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          loading: action.payload,
          loaded: action.payload ? false : state.events.loaded,
          error: false
        }
      };
    case LOADING_EVENTS_ERROR:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          loaded: true,
          error: true
        }
      };
    case FIND_EVENTS:
      return {
        ...state,
        events: {
          rows: formatResultsData(action.payload.events.result, eventsConfig),
          ...action.payload.params,
          count: action.payload.events.count,
          loaded: true,
          error: false
        }
      };
    case GET_EVENT:
      return {
        ...state,
        eventDetails: { ...state.eventDetails, data: formatCaseData(action.payload) }
      };
    case LOADING_BOOKINGS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          loading: action.payload,
          loaded: action.payload ? false : state.bookings.loaded,
          error: false
        }
      };
    case LOADING_BOOKINGS_ERROR:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          loading: false,
          loaded: true,
          error: true
        }
      };
    case FIND_BOOKINGS:
      return {
        ...state,
        bookings: {
          rows: formatResultsData(action.payload.bookings.result, bookingsConfig),
          ...action.payload.params,
          count: action.payload.bookings.count,
          loaded: true,
          error: false
        }
      };
    case GET_BOOKING:
      return {
        ...state,
        bookingDetails: { ...state.bookingDetails, data: formatCaseData(action.payload) }
      };
    case GET_CASE_SELECT_ITEMS:
      //console.log("GET_CASE_SELECT_ITEMS", action.payload);
      return {
        ...state,
        casesSelectItems: action.payload
      };
    case GET_EVENT_SELECT_ITEMS:
      //console.log("GET_EVENT_SELECT_ITEMS", action.payload);
      return {
        ...state,
        eventsSelectItems: action.payload
      };
    case GET_BOOKING_SELECT_ITEMS:
      //console.log("GET_EVENT_SELECT_ITEMS", action.payload);
      return {
        ...state,
        bookingSelectItems: action.payload
      };
    case GET_API_VER:
      return { ...state, apiVersion: action.payload };
    case PRINT_DATA:
      return { ...state, printData: action.payload };
    default:
      return state;
  }
}

// ======================= HELPERS =======================
/** Format data comming from api */
function formatResultsData(rows, config) {
  return rows.map(row => {
    const out = {};
    out.key = row[config.key];
    Object.keys(config.display).forEach(key => {
      let val1 = row[key];
      if (val1 === 'NULL') val1 = '';
      out[key] = val1;
    });
    return out;
  });
}

function formatCaseData(data) {
  const out = { ...data };
  out.AddtlInfo = data.AddtlInfo ? JSON.parse(data.AddtlInfo) : null;
  out.MainInfo = data.MainInfo ? JSON.parse(data.MainInfo) : null;
  return out;
}
