import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { clearPrintData } from '../../reducers/VfcReducer';
import htmlForPrinting from './htmlForPrinting';

const useStyles = makeStyles(theme => ({
  printContainer: {
    display: 'none',
  },
  printIframe: {
    height: 0,
    width: 0,
    position: 'absolute'
  },
}));

function PrintData(props) {
  const classes = useStyles();
  const { config } = props;
  const [ headings, setHeadings ] = useState([]);
  const [ rows, setRows ] = useState([]);

  useEffect(() => {
    setHeadings(getHeadings());
    setRows(getData());

    return () => {
      if (props.printData) props.clearPrintData();
    }
  }, []);

  useEffect(() => {
    if (headings.length > 0 && rows.length > 0) {
      printAll();
      props.clearPrintData();
    }

  }, [headings, rows]);

  const getHeadings = () => {
    const obj = [];
    Object.values(config.display).forEach(val => {
      obj.push({ label: val.label, seq: val.seq });
    });
    return obj.sort((a, b) => a.seq - b.seq).map(obj => obj.label);
  }

  const getData = () => {
    return props.data.map(getRow);
  }

  const getRow = (row) => {
    const obj = [];
    Object.entries(config.display).forEach(([key, val]) => {
      obj.push({ text: row[key], seq: val.seq });
    });
    return obj.sort((a, b) => a.seq - b.seq).map(obj => obj.text);
  }

  const printAll = () => {
    const content = document.getElementById("print-list");
    let html = htmlForPrinting(content.innerHTML, `PTS Archive Browser`);
    const pri = document.getElementById("print-iframe2").contentWindow;
    pri.document.open();
    pri.document.write(html);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  return (
    <>
      <div id="print-list" className={classes.printContainer}>
        <table>
          <thead>
            <tr>
              {headings.map((header, idx) => <th key={idx}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, idx) => (
              <tr key={idx}>{row.map((cell, idx2) => <td key={idx2}>{cell}</td>)}</tr>
            ))}
          </tbody>
        </table>
      </div>
      <iframe id="print-iframe2" className={classes.printIframe} title="PTS Archive Browser "></iframe>
    </>
  );

}

const mapStateToProps = state => {
  return {
    data: state.vfc.printData
  };
}

export default connect(mapStateToProps, { clearPrintData })(PrintData);
