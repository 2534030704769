import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index';

import createRootReducer from '../reducers';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  whitelist: ['offline', 'theme', 'user', 'units', 'events'],
  storage,
};

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist: false,
  persistAutoRehydrate: false,
});

const persistedReducer = persistReducer(
  persistConfig,
  offlineEnhanceReducer(createRootReducer(history))
);

let store;

if (process.env.NODE_ENV === 'development') {
  store = createStore(
    persistedReducer,
    compose(
      offlineEnhanceStore,
      composeEnhancers(
        applyMiddleware(
          ReduxThunk,
          offlineMiddleware,
          routerMiddleware(history)
        )
      )
    )
  );
} else {
  store = createStore(
    persistedReducer,
    compose(
      offlineEnhanceStore,
      applyMiddleware(ReduxThunk, offlineMiddleware, routerMiddleware(history))
    )
  );
}

let persistor = persistStore(store);

export default { store, persistor };
