import React, { Fragment, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import htmlForPrinting from './htmlForPrinting';
import headerNames from '../../config/headerNames.json';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  printBtn: {
    position: 'absolute',
    display: 'inline-block',
    right: 0,
    top: 0,
    opacity: 0.2,
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 1
    },
  },
  paper: {
    marginBottom: 20,
    boxSizing: 'border-box',
    padding: '10px 20px',
    position: 'relative',
    '& h4': {
      textAlign: 'center',
      color: '#0056aa',
      margin: '0.75em 0 1em',
    },
  },
  vTable: {
    marginBottom: 20,
    '& th': {
      border: '1px solid #ccc',
      padding: '2px 5px',
      whiteSpace: 'nowrap'
    },
    '& td': {
      border: '1px solid #ccc',
      padding: '2px 5px',
    },
  },
  hTable: {
    marginBottom: 20,
    '& th': {
      border: '1px solid #ccc',
      padding: '2px 5px',
    },
    '& td': {
      border: '1px solid #ccc',
      padding: '2px 5px',
    },
  },
  tableNoBorder: {
    '& th': {
      padding: '2px 5px',
      border: 'none',
    },
    '& td': {
      padding: '2px 5px',
      border: 'none',
    },
  }
}));

function print(ref, caseNo) {
  const content = ref.current;
  let html = htmlForPrinting(content.innerHTML, `PTS Archive Browser | vfcMainId: ${caseNo}`);
  const pri = document.getElementById("print-iframe").contentWindow;
  pri.document.open();
  pri.document.write(html);
  pri.document.close();
  pri.focus();
  pri.print();
}

function getHeaderName(str) {
  const predefinedName = headerNames[str];
  if (predefinedName) return predefinedName;
  const result = str.replace(/([A-Z])/g, ' $1')
    .replace('Number', "No");
  return result;
}

export function Wrapper(props) {
  const classes = useStyles();
  const ref = useRef();
  return (
    <Paper className={classes.paper} ref={ref}>
      <div className={classes.printBtn}>
        <IconButton aria-label="print" onClick={() => print(ref, props.caseNo)}>
          <LocalPrintshopIcon />
        </IconButton>
      </div>
      {props.children}
    </Paper>
  );
}

/** Deepl object cloning */
function cloneObj(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function getTableHeaders (arr) {
  const result = [];
  arr.forEach(row => {
    Object.keys(row).forEach(key => {
      if (result.indexOf(key) === -1) result.push(key);
    });
  });
  return result;
}

function isDate(str) {
  if (typeof str !== 'string' || str.length > 24) return false;
  var dateReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  if (str.match(dateReg) !== null && moment(str).isValid()) return true;
}

function formatValue(str) {
  if (isDate(str))
    return moment(str).format('MM/DD/YYYY HH:MM:SS');
  if (typeof str === 'object') {
    if (str === null) return '';
    if (str === true) return 'TRUE';
    if (str === false) return 'FALSE';
    return <DisplayData variant="V" data={str} noBorder />
  }
  return str;
}

/** Output Horizontal table from object */
function HTable(props) {
  const obj = cloneObj(props.obj);
  const classes = useStyles();
  const isArray = Array.isArray(obj);
  let arr;
  if (isArray) {
    arr = obj;
  } else {
    arr = [obj];
  }

  const tableHeaders = getTableHeaders(arr);
  return (
    <table className={clsx(props.noBorder ? classes.tableNoBorder : classes.hTable, 'h-table')}>
      <thead>
        <tr>
          {tableHeaders.map((key, idx) => <th key={idx} title={key}>{getHeaderName(key)}</th>)}
        </tr>
      </thead>
      <tbody>
        {arr.map((row, idx) =><tr key={idx}>
          {tableHeaders.map((key, idx2) => {
            const val = formatValue(row[key]);
            if (val !== undefined && val !== null) return <td key={idx2}>{val}</td>;
            return <td key={idx2}></td>;
          })}
        </tr>)}
        <tr></tr>
      </tbody>
    </table>
  );
}

/** Output Vertical table from object */
function VTable(props) {
  const classes = useStyles();
  const obj = cloneObj(props.obj);
  return (
    <table className={clsx(props.noBorder ? classes.tableNoBorder : classes.vTable, "v-table")}>
      <tbody>
        {Object.entries(obj).map(([key, val], idx) => <tr key={idx}>
          <th title={key}>{getHeaderName(key)}</th>
          <td>{formatValue(val)}</td>
        </tr>)}
      </tbody>
    </table>
  );
}

/**
 * Display data component
 *   properties
 *   title - H4 title
 *   subtitle - H5 subtitle
 *   variant  - V for vertical table, H for horizontal
 *   filters - array of keys that shouldn't be printed
 *   paper (true/false) - wrap table with <Paper> element
 */
function DisplayData(props) {
  const { title, subtitle, variant, filters, paper, noBorder, caseNo } = props;
  const rawData = props.data;
  const isArray = Array.isArray(rawData);
  const isEmpty = 
    rawData === undefined ||
    rawData === null ||
    ( isArray && rawData.length === 0) ||
    (!isArray && Object.keys(rawData).length === 0);
  if (isEmpty) return '';
  let data;
  if (isArray) {
    data = rawData.map(obj => {
      const result = cloneObj(obj);
      if (filters) filters.forEach(filter => delete result[filter]);
      return result;
    });
  } else {
    data = cloneObj(rawData);
    if (filters) filters.forEach(filter => delete data[filter]);
  }

  const Content = (
    <Fragment>
      {title && <h4>{title}</h4>}
      {subtitle && <h5>{subtitle}</h5>}
      {variant === 'H' && <HTable obj={data} noBorder={noBorder} />}
      {isArray &&
        variant === 'V' &&
        data.map((d, idx) => {
          if (typeof d === 'string') {
            return idx > 0 ? `, ${d}` : d;
          } else {
            return <VTable obj={d} key={idx} noBorder={noBorder} />;
          }
        })}
      {!isArray && variant === 'V' && <VTable obj={data} noBorder={noBorder} />}
    </Fragment>
  );

  return (
    <Fragment>
      {paper && <Wrapper caseNo={caseNo}>{Content}</Wrapper>}
      {!paper && Content}
    </Fragment>
  );
}

export default DisplayData;
