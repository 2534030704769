import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './Routes';
import store from './config/configureStore';
import { history } from './config/configureStore';
import ScrollToTop from './utils/ScrollToTop';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DayTheme, NightTheme } from './theme';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
import { AnimatePresence } from 'framer-motion';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Spinner from 'components/Spinner';
import './assets/base.scss';
import './icons';

const App = props => {
  const snackbarRef = useRef();
  const { showSpinner } = props.ui;
  const themeMode = () => {
    let theme = DayTheme;
    if (props.mode === 'night') {
      theme = NightTheme;
    }
    return theme;
  };

  const updateApp = registration => {
    window.localStorage.clear();
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: reg => updateApp(reg) });
  }, []);

  const closeSnackbar = key => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  return (
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={store.persistor}>
        <CssBaseline />
        <ScrollToTop>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={themeMode()}>
            <SnackbarProvider
              ref={snackbarRef}
              action={key => (
                <IconButton onClick={closeSnackbar(key)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}>
                <Notifier />
                <AnimatePresence>
                  <Routes />
                </AnimatePresence>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </ScrollToTop>
      </PersistGate>
      {showSpinner && <Spinner />}
    </ConnectedRouter>
  );
};

const mapStateToProps = state => ({
  mode: state.theme.mode,
  ui: state.ui,
});

export default connect(mapStateToProps)(App);
