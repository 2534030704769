import React from 'react';
import { connect } from 'react-redux';

import {
  Card,
  CardContent,
  Button,
  FormControl,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { submitLoginForm } from '../reducers/UserReducer';
import projectLogo from '../assets/images/hero-image.png';
import projectLogoNight from '../assets/images/hero-image-night.png';
import splashScreen from '../assets/images/login-splash.jpg';

const useStyles = makeStyles(theme => ({
  pageWrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover'
  },
  card: {
    width: 450,
    maxWidth: '95%',
    '& img': {
      width: '100%'
    }
  }
}));

const PageLogin = props => {
  const { themeMode } = props;
  const classes = useStyles();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleUsernameChange = event => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const submitForm = event => {
    event.preventDefault();
    props.submitLoginForm(username, password);
  };

  if (window.location.search) {
    const token = window.location.search.split('=')[1];
    if (token) {
      const currentToken = localStorage.getItem('auth');

      if (currentToken !== token) {
        localStorage.setItem('auth', token);
        window.location.reload();
      }
    }
  }

  return (
    <div className={classes.pageWrap}>
      <Card className={classes.card}>
        <img
          alt="Cad Mobile"
          src={themeMode === 'day' ? projectLogo : projectLogoNight}
        />
        <CardContent>
          <form onSubmit={submitForm}>
            <div className="mb-3">
              <FormControl className="w-100">
                <TextField
                  required
                  variant="outlined"
                  label="Username"
                  fullWidth
                  placeholder="Enter your username"
                  type="text"
                  value={username}
                  onChange={handleUsernameChange}
                />
              </FormControl>
            </div>
            <div className="mb-3">
              <FormControl className="w-100">
                <TextField
                  required
                  variant="outlined"
                  label="Password"
                  fullWidth
                  placeholder="Enter your password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </FormControl>
            </div>
            <div className="text-center">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                className="my-2">
                Login
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode
});

export default connect(mapStateToProps, {
  submitLoginForm
})(PageLogin);
