/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
//import InputAdornment from '@material-ui/core/InputAdornment';
//import Stack from '@material-ui/core/Stack';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em'
  },
  // filters: {
  //   padding: 20,
  //   //marginBottom: 20,
  //   margin: '10px 10px 10px 10px',
  // },
  filterAction: {
    textAlign: 'right',
    marginTop: 20,
  },
  filter: {
    //margin: '0 0px 10px',
    //minWidth: 140,
    width: "100%",
  },
  datePicker: {
    // margin: '0 0px 0px',
    // paddingTop: 6,
    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6
    }
  },
  // select: {
  //   width: "100%",
  //   margin: '0px 0px 10px 0px',
  // },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  reset: {
    marginRight: 20
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em',
  },
  buttonProgress: {
    color: '#0153a3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  label: {
    //variant: "h3",
    fontSize: 14,
    margin: "0px 0px 0px 0px"
  },
}));

function FiltersPanel(props) {
  const classes = useStyles();
  // eslint-disable-next-line  
  const filters = props.config.display;//const [filters, setFilters] = useState(props.config.display);  
  const groupLabels = props.config.groupLabels;
  const blankItemWeight = props.config.blankItemWeight;  
  const { loaded } = props.data;
  const selectItems = props.selectItems;

  const [filterValues, setFilterValues] = useState({});
  const [loading, setLoading] = React.useState(false);
  const filterRefs = {};
  const [pickerValues, setPickerValues] = useState({});

  Object.values(filters).forEach(({ dbName }) => {
    // eslint-disable-next-line
    filterRefs[dbName] = useRef();
  })

  useEffect(() => {
    loaded && loading && setLoading(false);
  }, [loaded]);

  const search = () => {
    const { orderBy, rowsPerPage, orderDirection } = props.data;
    const newFilterValues = { ...filterValues };
    Object.entries(pickerValues).forEach(([key, val]) => {
      newFilterValues[key] = {
        type: 'date',
        from: moment(val.from).format('YYYY-MM-DD') + ' 00:00:00.0',
        to: moment(val.to).format('YYYY-MM-DD') + ' 23:59:59.0'
      }
    });
    const options = {
      pageNo: 0,
      orderBy,
      rowsPerPage,
      orderDirection,
      filters: newFilterValues
    };
    props.getData(options);
    setLoading(true);
  };

  const handleFilterChange = filter => ev => {
    const values = { ...filterValues };
    values[filter.dbName] = { type: filter.type, search: ev.target.value };
    setFilterValues(cleanFilters(values));
  };

  const getTextFilter = (filter, idx) => {
    const width = filter.width;
    return (
      <Grid item xs={width}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>
            {filter.label}
          </Typography>

          <TextField
            type="search"
            key={idx}
            className={classes.filter}
            variant="outlined"
            onChange={handleFilterChange(filter)}
            inputRef={filterRefs[filter.dbName]}
          />
        </div>
      </Grid>
    )
  }

  // const getTypographyFilter = (filter, idx) => {
  //   const width = filter.width;
  //   return (
  //     <Grid item xs={width}>        
  //         <Typography className={classes.label} display="block">
  //           {filter.label}
  //         </Typography>
  //     </Grid>
  //   )
  // }

  const handleDateChange = (date, dbName, param) => {
    const value = pickerValues[dbName] ? pickerValues[dbName] : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = date;
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[dbName] = value;
    } else {
      delete currentPickerValue[dbName];
    }

    setPickerValues(currentPickerValue);
  }

  const getDateFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName] ? pickerValues[filter.dbName] : { from: null, to: null };
    const minToValue = value.from ? value.from : undefined;
    const maxFromValue = value.to ? value.to : new Date();
    const width = filter.width;
    /*Stack*/
    return (
      <Grid item xs={width}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>{filter.label}</Typography>
          <KeyboardDatePicker
            className={classes.datePicker}
            clearable
            value={value.from}
            placeholder='__/__/____' //{`${filter.label} From`}
            onChange={date => handleDateChange(date, filter.dbName, 'from')}
            maxDate={maxFromValue}
            error={false}
            helperText={null}
            format="MM/DD/yyyy"
            inputVariant='outlined'
          />
          <KeyboardDatePicker
            className={classes.datePicker}
            clearable
            value={value.to}
            placeholder='__/__/____' //{`${filter.label} To`}
            onChange={date => handleDateChange(date, filter.dbName, 'to')}
            minDate={minToValue}
            maxDate={new Date()}
            error={false}
            helperText={null}
            format="MM/DD/yyyy"
            inputVariant='outlined'
          />
        </div>
      </Grid>
    )
  };

  const getDateSingleFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName] ? pickerValues[filter.dbName] : { from: null, to: null };
    const width = filter.width;
    return (
      <Grid item xs={width}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography style={{ marginLeft: 10 }} className={classes.label}>{filter.label}</Typography>
          <KeyboardDatePicker
            key={idx}
            className={classes.datePicker}
            clearable
            value={value.from}
            placeholder='__/__/____/' //{filter.label}
            error={false}
            helperText={null}
            onChange={date => handleDateChange(date, filter.dbName, 'single')}
            format="MM/DD/yyyy"
            inputVariant='outlined'
          />
        </div>
      </Grid>
    )
  };

  const getSelectFilter = (filter, idx) => {
    const width = filter.width;
    //console.log("getSelectFilter", selectItems);

    const options = selectItems[filter.selectName];
    //console.log("getSelectFilter", options);

    //console.log(optionStringArray);
    //console.log(optionStringArray.join());
    //const options = JSON.parse(optionStringArray.join());
    //console.log("getSelectFilter", selectItems);
    //console.log("getSelectFilter", options);
    
    return (
      <Grid item xs={width}>
        <div key={idx} style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', gap: 10 }}>
          <Typography className={classes.label} display="block">
            {filter.label}
          </Typography>
          <FormControl className={classes.select} key={idx}>
            {/* <InputLabel id={`filter-${filter.dbName}`}>{filter.label}</InputLabel> */}
            <Select
              // labelId={`filter-${filter.dbName}`}
              variant="outlined"
              value={filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''}
              onChange={handleFilterChange(filter)}
            >
              <MenuItem value=""> </MenuItem>
              {/* { options.map((option, idx) => <MenuItem value={option} key={idx}>{option}</MenuItem>)} */}
              { options.map((option, idx) => <MenuItem value={option.ID} key={idx}>{option.Description}</MenuItem>)}
              {/* { options.map((option, idx) => {console.log(option)})} */}
            </Select>
          </FormControl>
        </div>
      </Grid>
    );
  }

  const getGroupItem = (groupId) => {
    const blankWeight = blankItemWeight[groupId];
    //console.log(groupId, blankWeight);
    return (
      <Grid container spacing={2}>
        { blankWeight > 0 && (<Grid item xs={blankWeight}> </Grid>) }
        {Object.values(filters).map((filter, idx) => {
          if (filter.groupId == groupId) {
            switch (filter.type) {
              case 'date':
                return getDateFilter(filter, idx);
              case 'date-single':
                return getDateSingleFilter(filter, idx);
              case 'select':
                if( selectItems && selectItems[filter.selectName])
                  return getSelectFilter(filter, idx);
                break;
              // case 'label':
              //   return getTypographyFilter(filter, idx);
              default:
                return getTextFilter(filter, idx);
            }
          }
        })}
      </Grid>
    )
  }

  const getGroupItemLabel = (itemLabel) => {
    if (itemLabel.labelCount > 1)
      return (
        <div style={{ display: "flex", flexDirection: 'column' }}>
          {itemLabel.labels.map((label, idx) =>
            <Typography style={itemLabel.labelStyle} className={classes.label}>
              {label}
            </Typography>
          )}
        </div>
      );
    else if (itemLabel.labelCount == 1)
      return (
        <Typography style={itemLabel.labelStyle} className={classes.label}>
          {itemLabel.labels[0]}
        </Typography>
      );
  }

  const clearForm = () => {
    Object.values(filterRefs).forEach(({ current }) => {
      if (current) current.value = '';
    })
    setFilterValues({});
    setPickerValues({});
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {Object.values(groupLabels).map((itemLabel, idx) => {
          return (
            <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>
              {getGroupItemLabel(itemLabel)}
              {getGroupItem(itemLabel.groupId)}
            </div>
          )
        })}

        <div className={classes.filterAction}>
          <Button
            startIcon={<ClearIcon />}
            onClick={clearForm}
            className={classes.reset}
            disabled={loading}
          >
            Clear All
          </Button>
          <div className={classes.searchBtnWrap}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<SearchIcon />}
              onClick={search}
              disabled={loading}
            >
              Search
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default FiltersPanel;

function cleanFilters(obj) {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.search !== '') result[key] = val;
  });
  return result;
}
