/**
 * Menu item configurations for desktop and mobile
 */

export default [  
  {
    label: 'CAD Events',
    tooltip: 'CAD Events',
    link: '/Events',
    icon: 'calendar-week',
  },
  {
    label: 'Cases',
    tooltip: 'Offense/Case Data',
    link: '/Cases',
    icon: 'clipboard',
  },
  {
    label: 'Booking',
    tooltip: 'Booking Data',
    link: '/Booking',
    icon: 'address-book',
  },
];
