/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { 
  findEvents, sortEvents, getEvent, printEventsData, getEventsSelectItems
} from '../../reducers/VfcReducer';
import DataTable from '../../components/DataTable';
import Report from './EventReport';
import Paper from '@material-ui/core/Paper';
import FiltersPanel from '../../components/FiltersPanel';
import eventsConfig from '../../config/cad-events-table.json';
import filtersConfig from '../../config/cad-event-search-filters.json';
import PrintData from '../../components/PrintData';
import PrintIcon from '@material-ui/icons/Print';
import appConfig from '../../config/appConfig.json';

const useStyles = makeStyles(theme => ({
  page: {
    boxSizing: 'border-box'
  },
  searchBar: {
    padding: '30px 20px 30px',
    '& > h3': {
      color: '999',
      paddingLeft: 10,
    },
    '& > h4': {
      color: '#999',
      fontSize: 20
    },
  },
  tabs: {
    marginBottom: 30,
  },
  action: {
    textAlign: 'right',
    padding: '1em 0',
    '& button': {
      marginLeft: 10,
      '&:first-child': {
        marginLeft: 0,
      }
    }
  }
}));

function ReportList(props) {
  const classes = useStyles();
  const { events, eventDetails, printData, eventsSelectItems } = props.vfc;
  const webSocket = props.webSocket;
  const { loading, loaded, rows, error } = events;
  const [selected, setSelected] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (eventDetails.data && selected) setShowDetails(true);
  }, [eventDetails]);

  const getEventsSelectItems = () => {
    //console.log("p2:", Date.now());
    props.getEventsSelectItems();
  }
  useEffect(() => {  
    //console.log("p1:", Date.now(), webSocket);
    if(webSocket)
      getEventsSelectItems();
    
  }, [loaded, loading, webSocket]);

  const getData = options => {
    props.findEvents(options);
  };

  const sort = name => {
    props.sortEvents(name);
  };

  const selectRow = row => {
    setSelected(row);
  };

  const getReport = () => {
    //console.log("getReport()", selected.vfcMainId);
    props.getEvent(selected.vfcMainId);
  };

  const closeDetails = () => {
    setShowDetails(false);
  };

  const tableDblClick = () => {
    getReport();
  }

  const printList = () => {
    const msg = `The result array exceeds the maximum number of ${appConfig.maxPrintResults} rows.\n` +
    `Continue printing first ${appConfig.maxPrintResults} rows?`;
    if (events.count > appConfig.maxPrintResults && !window.confirm(msg) ) return;
    props.printEventsData();
  }

  return (
    <div className={classes.page}>
      <Paper square className={classes.searchBar}>
        <h3>Archive Browser-CAD Events</h3>
        { eventsSelectItems && (<FiltersPanel getData={getData} config={filtersConfig} data={events} selectItems={eventsSelectItems}/>) }
        {rows.length > 0 && (
          <Fragment>
            <h4>Search Results</h4>
            <div className={classes.action}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={printList}
                startIcon={<PrintIcon />}>
                Print List
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={getReport}
                startIcon={<AssignmentIcon />}
                disabled={!selected}>
                Show Details
              </Button>
            </div>
            <DataTable
              getData={getData}
              data={events}
              config={eventsConfig}
              sort={sort}
              selectRow={selectRow}
              dblClick={tableDblClick}
            />
          </Fragment>
        )}
        {loading && rows.length === 0 && <LoadingData />}
        {!loaded && !loading  && rows.length === 0 && <StartSearching />}
        {loaded && !loading  && rows.length === 0 && !error && <NoResults />}
        {loaded && !loading  && rows.length === 0 && error && <ErrorLoading />}
        {showDetails && <Report data={eventDetails.data} close={closeDetails} />}
      </Paper>
      {printData && <PrintData config={eventsConfig}/>}
    </div>
  );
}

function NoResults() {
  return <h4>Sorry, no results match your search criteria.</h4>;
}

function LoadingData() {
  return <h4>Loading the data, plase wait...</h4>;
}

function StartSearching() {
  return <h4>Please define your search criteria.</h4>;
}

function ErrorLoading() {
  return <h4>There was an error while recieving the data.</h4>;
}

const mapStateToProps = state => {
  return {
    vfc: state.vfc,
    webSocket: state.websocket.websocket
  };
};

export default connect(mapStateToProps, {
  findEvents,
  sortEvents,
  getEvent,
  printEventsData,
  getEventsSelectItems,
})(ReportList);
