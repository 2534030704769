import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Dialog, DialogActions, DialogContent, DialogTitle, Button
} from '@material-ui/core';
import DisplayData, { Wrapper } from '../../components/DisplayData';
import htmlForPrinting from '../../components/DisplayData/htmlForPrinting';

const useStyles = makeStyles(theme => ({
  body: {
    background: '#eee',
  },
  printIframe: {
    height: 0,
    width: 0,
    position: 'absolute'
  },
}));

function Contacts (props) {
  const { data } = props;
  if (!data) return '';
  return data.map((contact, idx) => {
    const { MainInfo, AddtlInfo } = contact;
    return (
      <Wrapper key={idx} caseNo={props.caseNo}>
        {/* <DisplayData variant="V" data={contact} title="Contact" filters={['MainInfo', 'AddtlInfo']}/> */}
        <DisplayData variant="H" data={AddtlInfo} subtitle="Additional Info" />
        <DisplayData variant="V" data={MainInfo} subtitle="Main Info"/> 
      </Wrapper>
    );
  });
}

function CaseDetails (props) {
  const { data } = props;
  if (!data) return '';

  return(
    <Wrapper caseNo={props.caseNo}>
      <DisplayData
        data={data}
        variant="V"
        filters={[
          'MainInfo',
          'AddtlInfo'
        ]}     // 'Contacts',  'Narratives', 'Incidents', 'vfcCaseId', 'Arrests', 'Properties', 'SupplementalCases', 'UsedForce', 'Vehicles', 
        title={'Case Details'}
        caseNo={props.vfcMainId}//caseNo
      />
      <DisplayData 
        variant="H"
        subtitle="Additional Info"
        caseNo={props.vfcMainId}//caseNo
        data={data.AddtlInfo}
      />
    </Wrapper>
  );
}

function MainData (props) {
  const { data } = props;
  if (!data) return '';

  //CaseNo
  return(
    <Wrapper caseNo={props.vfcMainId}>
      <h4>Main Info</h4>
      {data.map((d, idx) => <DisplayData key={idx} variant="V" data={d} subtitle="Main Data" />)}
    </Wrapper>
  );
}

function Incident(props) {
  if (!props.data) return '';
  const incident = props.data;

  return (
    <Wrapper caseNo={props.caseNo}>
      <DisplayData variant="H" data={incident} title= "Incidents" filters={['vfcIncidentId', 'vfcCaseId']} />
    </Wrapper>
  );
}

function Arrests(props) {
  if (!props.data || props.data.length === 0 ) return '';
  const arrests = props.data;
  
  return (
    <Wrapper caseNo={props.caseNo}>
      <h4>Arrests</h4>
      {arrests.map((arrest, idx) => <DisplayData key={idx} data={arrest} variant="V" filters={['vfcCaseId', 'vfcArrestId']} />)}
    </Wrapper>
  );
}

function Properties(props) {
  if (!props.data || props.data.length === 0 ) return '';

  return props.data.map((property, idx) => (
    <DisplayData
      title="Property"
      key={idx}
      data={property}
      variant="V"
      filters={['vfcPropertyId', 'vfcCaseId']}
      getId={props.getId}
      caseNo={props.caseNo}
      paper
    />
  ));
}

function UsedForce(props) {
  if (!props.data || props.data.length === 0 ) return '';
  return (
    <Wrapper caseNo={props.caseNo}>
      <h4>Used Force</h4>
      {props.data.map((property, idx) => <Fragment key={idx}>
        <DisplayData
          data={property}
          variant="H"
          filters={['Info']}
        />
        <DisplayData
          subtitle="Info"
          data={property.Info}
          variant="V"
        />
      </Fragment>)}
    </Wrapper>
  );
}

function Vehicles(props) {
  if (!props.data || props.data.length === 0 ) return '';
  return props.data.map((property, idx) =>
    <DisplayData
      title="Vehicle"
      key={idx}
      data={property}
      variant="V"
      filters={['vfcCaseId', 'vfcVehicleId']}
      caseNo={props.caseNo}
      paper
    />
  );
}

function Report(props) {
  const classes = useStyles();
  const { data } = props;
  const caseNo = data.vfcMainId;//CaseNumber;

  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleClose = () => {
    props.close();
  };

  const printAll = () => {
    const content = document.getElementById("report-dialog");
    let html = htmlForPrinting(content.innerHTML, `PTS Archive Browser | vfcMainId: ${caseNo}`);
    const pri = document.getElementById("print-iframe").contentWindow;
    pri.document.open();
    pri.document.write(html);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  if (!data) return 'No data';

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll="paper"
      fullWidth={true}
      maxWidth={'md'}
    >
      <iframe id="print-iframe" className={classes.printIframe} title="PTS Archive Browser "></iframe>
      <DialogTitle id="scroll-dialog-title">Case Details</DialogTitle>
      <DialogContent dividers={true} className={classes.body} id="report-dialog">     
        <CaseDetails data={data} caseNo={caseNo}/>
        <MainData data={data.MainInfo} caseNo={caseNo} />
        {/* <Arrests data={data.Arrests} caseNo={caseNo} />
        {data.Narratives !== undefined && <DisplayData
          title="Narrative"
          data={data.Narratives}
          variant="V"
          caseNo={caseNo}
          filters={['vfcNarrativeId', 'vfcCaseId']}
          paper
        />}
        <Contacts data={data.Contacts} caseNo={caseNo} />
        {data.Incidents !== undefined && data.Incidents.map((incident, idx) => <Incident
          key={idx}
          data={incident}
          caseNo={caseNo}
        />)}
        <Properties data={data.Properties} caseNo={caseNo} />
        {data.SupplementalCases !== undefined && <DisplayData
          title="Follow ups"
          data={data.SupplementalCases}
          variant="H"
          caseNo={caseNo}
          paper
        />}
        <UsedForce data={data.UsedForce} caseNo={caseNo} />
        <Vehicles data={data.Vehicles} caseNo={caseNo} /> */}
      </DialogContent>
      <DialogActions>
      <Button onClick={printAll} color="primary">
          Print
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Report;
